import styled from "styled-components";

export const ProfileLayoutWrapper = styled.section`
  max-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  footer {
    margin-top: auto;
  }
`;

export const ProfileContent = styled.div`
  display: flex;
  height: 100%;
`;

export const Div = styled.div`
  width: 80vw;
  @media (max-width: 992px) {
    width: 100%;
  }
`;