import { LogoImage } from '../Login/Login.styled'
import Logo from '../../images/logo.webp'
import { LoaderWrapper } from './Loader.styled'
type Props = {}

function Loader({}: Props) {
  return (
    <LoaderWrapper><LogoImage src={Logo} alt="logo" /></LoaderWrapper>
  )
}

export default Loader