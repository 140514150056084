import React, { useState } from 'react'
import { AuthLink, ButtonsWrapper, HeaderLink, HeaderList, HeaderWrapper, Li, LogoWrapper, Overlay } from './Header.styled'
import { Container, Row, Col } from 'react-grid-system'
import Logo from '../../../images/logo.webp'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import BurgerButton from './BurgerButton'
import { useMediaQuery } from 'react-responsive'
type Props = {}

const Header = ({}: Props) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  const is_active = cookies.get('is_active');
  const [isOpen, setIsOpen] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const navigate = useNavigate()
  const handleLogout = () => {
    if(token) {
      cookies.remove('token')
      cookies.remove('is_active')
      navigate('/')
    }
  }
  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen && isTabletOrMobile) {
      document.body.style.overflow = 'hidden';
      document.body.style.maxHeight = '100vh'; // Disable scrolling
    } else {
      document.body.style.overflow = ''; // Enable scrolling
    }
  };

  return (
    <>
      <HeaderWrapper>
        <Container>
          <Row align='center' justify='between' >
            <Col lg={2} xs={5} >
              <LogoWrapper>
                <Link to="/" >
                  <img src={Logo} alt="logo" />
                </Link>
              </LogoWrapper>
            </Col>
            <Col lg={10} xs={2} >
              <HeaderList isOpen={isOpen} onClick={handleMenuToggle}>
                <Li>
                  <HeaderLink to='/' >الرئيسية</HeaderLink>
                </Li>
                {/* <Li>
                  <HeaderLink to='/' >اختصار الروابط</HeaderLink>
                </Li> */}
                <Li>
                  <HeaderLink to='https://alajlanandaleid.com/' target='_blanck' >العجلان والعيد</HeaderLink>
                </Li>
                {/* <Li>
                  <HeaderLink to='/services'>الخدمات</HeaderLink>
                </Li> */}
                <Li>
                  <HeaderLink to='/recent'>أحدث الملفات</HeaderLink>
                </Li>
                {token && isTabletOrMobile && (
                  <>
                    <Li>
                      <HeaderLink to='/files' >الملفات</HeaderLink>
                    </Li>
                    <Li>
                      <HeaderLink to='/urls' >الروابط</HeaderLink>
                    </Li>
                  </>
                )}
                <ButtonsWrapper>
                  {token && is_active? (
                    <>
                      <AuthLink to='/profile' >الملف الشخصى</AuthLink>
                      <AuthLink to='/' onClick={handleLogout} >تسجيل خروج</AuthLink>
                    </>
                  ): (
                    <>
                      <AuthLink to='/register' >انشاء حساب</AuthLink>
                      <AuthLink to='/login' >تسجيل الدخول</AuthLink>
                    </>
                  )}
                </ButtonsWrapper>
              </HeaderList>
            </Col>
            {/* <Col lg={3} xs={4} >
              <ButtonsWrapper>
                {token ? (
                  <>
                    <AuthLink to='/profile' >الملف الشخصى</AuthLink>
                    <AuthLink to='/' onClick={handleLogout} >تسجيل خروج</AuthLink>
                  </>
                ): (
                  <>
                    <AuthLink to='/register' >انشاء حساب</AuthLink>
                    <AuthLink to='/login' >تسجيل الدخول</AuthLink>
                  </>
                )}
              </ButtonsWrapper>
            </Col> */}
            {isTabletOrMobile && <Col lg={1} xs={1}>
              <BurgerButton  isOpen={isOpen} setIsOpen={handleMenuToggle} />
            </Col>}
          </Row>
        </Container>
      </HeaderWrapper>
      <Overlay isOpen={isOpen} onClick={handleMenuToggle} />
    </>
  )
}

export default Header