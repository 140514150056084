import styled from "styled-components"


export const FooterWrapper = styled.footer`
  background-color: #171345;
  /* height: 100px; */
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 50%; */
`;

export const LinksWrapper = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0 0 16px 0;
  li, a{
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
  }
  li{
    position: relative;
    &::before{
      position: absolute;
      content: "";
      width: 1px;
      height: 100%;
      top: 0;
      left: -10px;
      background-color: #fff;
    }
    &:first-of-type{
      &::before{
        display: none;
      }
    }
  }
  @media (max-width: 435px) {
    flex-wrap: wrap;
    justify-content: center;
    li{
      &:last-of-type{
        &::before{
          /* display: none; */
        }
      }
    }
  }
`;

export const P = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 8px;
`;