import styled from "styled-components";

export const Wrapper = styled.div`
  min-height: calc(100vh - 278px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LogoImage = styled.img`
  max-width: 140px; 
  background-color: #252062;
  border-radius: 20px;
  margin: 20px auto;
  padding: 12px;
`;

export const P = styled.p`
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #627B97;
`;