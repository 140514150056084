import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const HeaderWrapper = styled.header`
  padding: 30px 0;
  background-color: #252062;
  position: relative;
  z-index: 10000;
`;

export const LogoWrapper = styled.div`
  max-width: 134.37px;
  min-width: 134.37px;
  img: {
    width: 100%;
    height: 100%;
  }
`;

export const HeaderList = styled.ul<{
  readonly isOpen?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  gap: 55px;
  @media (max-width: 1200px) {
    gap: 20px;
  }
  @media (max-width: 992px) {
    position: fixed;
    flex-direction: column;
    background-color: #252062;
    width: 60vw;
    bottom: 0;
    right: 0;
    transition: 0.3s ease all;
    transform: translateX(100%);
    height: calc(100vh - 144px);
    z-index: 12;
  }
  @media (max-width: 570px) {
    width: 75vw;
  }
  ${({isOpen}) => isOpen && css`
    @media (max-width: 992px){
      transform: translateX(0%);
    }
  `}
`;
export const DrawerList = styled.ul`
  min-width: 20vw;
  max-width: 20vw;
  padding: 60px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(#252062, #171345 60%);
  list-style: none;
  gap: 40px;
  /* margin-left: auto; */
  @media (max-width: 992px) {
    display: none;
  }
`;

export const Li = styled.li`
  
`;

export const HeaderLink = styled(Link)`
  text-decoration: none;
  font-size: 24px;
  font-weight: 400;
  line-height: 37.51px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 18px;
  margin-left: auto;
  @media (max-width: 992px) {
    flex-direction: column;
    margin-left: unset;
  }

`;

export const AuthLink = styled(Link)`
  font-size: 18px;
  font-weight: 400;
  line-height: 54px;
  padding: 0 20px;
  border-radius: 28px;
  color: #627B97;
  background-color: #EAE9E8;
  text-decoration: none;
  white-space: nowrap;
  text-align: center;
`;

export const Overlay = styled.div<{
  readonly isOpen?: boolean;
}>`
  width: 100vw;
  max-height: 100vh;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #627B9760;
  z-index: 1;
  visibility: 0;
  opacity: 0;
  display: none;
  @media(max-width: 992px) {
    ${({isOpen}) => isOpen && css`
      opacity: 1;
      display: flex;
      visibility: visible;
    `}
  }
`;

export const NavIcon = styled.div`
  position: relative;
  width: 40px; /* Adjust the width as needed */
  height: 35px; /* Adjust the height as needed */
  margin-left: auto;
  display: none;
  transform: translateX(-40px);
  @media (max-width: 992px) {
    display: block;
  }
  @media (max-width: 430px) {
    transform: translateX(-50px);
  }
  & span {
    display: block;
    position: absolute;
    height: 5px;
    width: 50%;
    background: #fff;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  & span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }

  & span:nth-child(odd) {
    left: 0;
    border-radius: 9px 0 0 9px;
  }

  & span:nth-child(1), & span:nth-child(2) {
    top: 6px /* rtl: 50px */;
  }

  & span:nth-child(3), & span:nth-child(4) {
    top: 18px;
  }

  & span:nth-child(5), & span:nth-child(6) {
    top: 30px;
  }

  &.open span:nth-child(1), &.open span:nth-child(6) {
    transform: rotate(45deg);
  }

  &.open span:nth-child(2), &.open span:nth-child(5) {
    transform: rotate(-45deg);
  }

  &.open span:nth-child(1) {
    left: 5px;
    top: 7px;
  }

  &.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
  }

  &.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  &.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  &.open span:nth-child(5) {
    left: 5px;
    top: 17px;
  }

  &.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 17px;
  }
  &:dir(rtl) {
    &.open span:nth-child(1), &.open span:nth-child(2){
      top: 15px;
    }
    &.open span:nth-child(5),  &.open span:nth-child(6){
      top: 5px;
    }
  }
`;
