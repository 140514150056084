
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import { resetPassword } from '../../Services/DataServices';
import { Col, Container, Row } from 'react-grid-system';
import { LoginForm, LogoImage, Submit } from '../../components/Login/Login.styled';
import { ErrorMessage, InputField, InputWrapper, Label } from '../../components/Common/InputTextField/InputTextField.styled';
import Logo from '../../images/logo.webp'
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from 'react-hook-form';
type Props = {}

type Obj = {
  email?: string;
  token?: string
}

interface ReseetData {
  password: string
  password_confirmation: string
}



const validationSchema = yup.object().shape({
  password: yup.string().required('ادخل كلمة المرور'),
  password_confirmation: yup.string().required('ادخل كلمة المرور'),
});

function ResetPassword({}: Props) {
  const [loading, setLoading] = useState(false);
  const [emailChangePassword, setEmailChangePassword] = useState('')
  const [tokenChangePassword, setTokenChangePassword] = useState('')
  const navigate = useNavigate()

  const location = useLocation();
  const url = location?.pathname;
  const regex = /\/([^/]+)\/([^/]+)$/;
  const match = url?.match(regex);

  
  useEffect(() => {
    if (match) {
      const email = match[2] as string;
      const token = match[1] as string;
      setEmailChangePassword(email);
      setTokenChangePassword(token);
    } else {
      console.log("Invalid URL format.");
    }

  }, [])  

  const { register, handleSubmit, formState: { errors } } = useForm<ReseetData>({
    resolver: yupResolver(validationSchema),
  });
  
  const onSubmit: SubmitHandler<ReseetData> = async (data) => {
    // console.log('clicked', data);
    const formData = new FormData();
    formData.append('email', emailChangePassword);
    formData.append('token', tokenChangePassword);
    formData.append('password', data.password);
    formData.append('password_confirmation', data.password_confirmation);
    // console.log(formData);
    try {
      setLoading(true);
      await resetPassword('user/reset/password', formData)
        .then((res) => {
          navigate("/");
        })
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Row justify='center' >
        <Col lg={5}  >
          <LoginForm onSubmit={handleSubmit(onSubmit)}>
            <LogoImage src={Logo} alt="logo" />
            <InputWrapper>
              <Label>كلمة المرور الجديدة</Label>
              <InputField type='password' placeholder="كلمة المرور" {...register("password")}/>
              {errors.password && <ErrorMessage>ادخل الاسم</ErrorMessage>}
            </InputWrapper>
            <InputWrapper>
              <Label> تأكيد كلمة المرور الجديدة</Label>
              <InputField type='password'  placeholder='تأكيد كلمة المرور' {...register("password_confirmation")}/>
              {errors.password_confirmation && <ErrorMessage>ادخل كلمة المرور</ErrorMessage>}
            </InputWrapper>
            <Submit type="submit" onClick={handleSubmit(onSubmit)} value={loading ? "loading":"تأكيد"}  />
          </LoginForm>
        </Col>
      </Row>
    </Container>
  )
}

export default ResetPassword