import styled from "styled-components";

export const SectionWrapper = styled.section`
  padding: 98px 0;
  background-color: #fff;
`;

export const Title = styled.h3`
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  padding-bottom: 16px;
  color: #627B97;
  border-bottom: 1px dotted #252062;
  margin-bottom: 16px;
`;

export const P = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #70808C;
  a{
    color: #70808C; 
  }
`;

export const FormWrapper = styled.div`
  margin-top: 54px;
  position: relative;
  /* max-height: 530px; */
  height: 530px;
  padding: 36px 40px;
  position: relative;
  @media(max-width: 992px) {
    max-height: unset;
    height: auto;
  }
  @media(max-width: 570px) {
    padding: 12px;
  }
`;

export const BgForm = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  @media(max-width: 992px) {
    opacity: 0.2;
  }
`;

export const Form = styled.form`
  padding: 30px 40px;
  background-color: #fff;
  border-radius: 12px;
  max-height: 466px;
  @media(max-width: 992px) {
    margin-bottom: 30px;
  }
`;

export const FormTitle = styled.h4`
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #000;
  margin-bottom: 18px;
`;

export const Box = styled.div`
  height: 200px;
  display: flex;
  padding: 9px;
  border-radius: 9px;
  gap: 12px;
  position: relative;
  background-color: #004EE114;
  overflow-y: auto;
  border: 1px dashed #1F4690;
  margin-bottom: 16px;
`;

export const ListOfFiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-bottom: 20px ;
`;

export const ItemFile = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 9px;
  position: relative;
  overflow: hidden;
  background-color: #00000010;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const RemoveBtn = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 5px;
  background-color: #25206260;
  color: #fff;
  width: 18px;
  height: 18px;
  line-height: 18px;
  cursor: pointer;
`;

export const ListWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  min-height: 188px;
  max-height: 288px;
  overflow-y: auto;
  flex-wrap: wrap;
  background-color: #004EE114;
  border: 1px dashed #1F4690 !important;
  border-radius: 9px;
  margin-bottom: 16px;
  .dropzone{
    min-height: 188px;
  }
  .dz-preview {
    background: transparent !important;
    margin: 8px !important;
  }
  .dz-image {
    width: 100px !important;
    height: 100px !important;
  }
  .dz-filename{
    display: none !important;
  }
  .dz-remove {
    /* Customize button styles here */
    /* background-color: red; */
    color: #252062;
    padding: 10px 0;
    border: none;
    cursor: pointer;
    text-decoration: none;
    
  }
`;

export const PlaceHolder = styled.div`
  &.dz-message{
    margin: 0 !important;
  }
  img {
    min-width: 74px;
    max-width: 74px;
    height: 64px;
    margin-bottom: 13px;
    object-fit: none;
    border-radius: 20px;
  }
  p{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #132A00;
    margin-bottom: 6px;
    span {
      color: #0E0AFF;
    }

  }
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #969DB2;
    &:last-of-type{
      display: inline-block;
      color: #132A00;
      line-height: 18px;
    }
  }
`;

export const Or = styled.div`
  position: relative;
  width: 100%;
  height: 12px;
  &::before{
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #E8EBF2;
  }
  &::after{
    position: absolute;
    content: 'or';
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 100%;
    background-color: #fff;
    text-align: center;
    font-size: 8px;
    color: #959DB2;
  }
`;

export const ImportFromLink = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
  margin-bottom: 4px;
`;

export const InputLink = styled.input`
  padding: 9px 18px;
  background-color: #F1F4F9;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #1A1A1A80;
  width: -webkit-fill-available;
  margin-bottom: 18px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 6px;
  p{
  }
`;

export const Submit = styled.button`
  width: 60px;
  height: 32px;
  line-height: 32px;
  background-color: #252062;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

export const Cancel = styled.button`
  width: 60px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  color: #000;
  background-color: transparent;
  border: 1px solid #1A1A1A2E
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  height: 100%;
  img{
    min-width: 150px;
    max-width: 150px;
    height: 150px;
    /* background-color: #627B97; */
    border-radius: 50%;
  }
  h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 75px;
    color: #70859A;
    text-align: center;
    max-width: 350px;
  }
  @media (max-width: 570px){
    flex-direction: column;
    width: auto;
    height: auto;
  }
`;
export const Div = styled.div`
  /* position: absolute;
  bottom: -125px;
  right: -12px;
  max-width: 446px; */
  @media(max-width: 992px) {
    position: relative;
    bottom: 0;
    right: 0;
  }
`;

export const ContentTitle = styled.h3`
  font-size: 28px;
  font-weight: 400;
  line-height: 28px;
  color: #505355;
  margin-bottom: 12px;
  margin-top: auto;
`;

export const ContentDescription = styled.p`
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  color: #505355;
`;

export const GuestFilesWrapper = styled.div`
  padding: 30px 0;
  background-color: #fff;
  min-height: calc(100vh - 338px)
`;