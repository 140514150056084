import React from 'react'
import Logo from '../../images/logo.webp';
import { Wrapper, P, LogoImage } from '../../components/VerifyPage/VerifyPage.styled';
// import { LogoImage, P, Wrapper } from '../VerifyPage.styled';
type Props = {}

function VereficationMessagePage({}: Props) {
  return (
    <Wrapper>
      <LogoImage src={Logo} alt='logo' />
      <P>يرجى تفعيل حسابك من خلال البريد الذي قمت بالتسجيل به حتى تستطيع التحكم في الملفات والروابط</P>
    </Wrapper>
  )
}

export default VereficationMessagePage