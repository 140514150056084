import { memo, Suspense, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Div, ProfileContent } from '../../Layouts/Layout/Layout.styled';
import Drawer from '../../components/Common/Header/Drawer';
import { Container, Col, Row } from 'react-grid-system';
import MemoFilesSection from '../../components/ProfilePage/FilesSection';
import MemoLinksSection from '../../components/ProfilePage/LinksSection';
import { ProfileContentWrapper, TableWrapper } from '../../components/ProfilePage/ProfilePage.styled';
import Cookies from 'universal-cookie';
import { getProfileData } from '../../Services/DataServices';
import MemoUpdateProfileForm from '../../components/ProfilePage/UpdateProfileForm';
// import { useParams } from 'react-router-dom';
// import MemoMainHostingSection from '../../components/MainHostingSection/MainHostingSection';
// import Bg from "../../images/bg.webp";
// import MemoNestedTabs from '../../components/NestedTabs/NestedTabs';
// import MemoFeaturesSection from '../../components/FeaturesSection/FeaturesSection';
// import MemoSpecialSection from '../../components/HomePage/SpecialSection/SpecialSection';
// import MemoClientOpinionSection from '../../components/HomePage/ClientOpinionSection/ClientOpinionSection';
// import MemoCollapseSection from '../../components/CollapseSection/CollapseSection';
// import FeaturesTabs from '../../components/FeaturesTabs/FeaturesTabs';



type ProfileData = {
  name: string
  username: string
  email: string
  is_public: boolean
}

type File = {
  delete_code: string;
  extension: string;
  image_url: string;
  title: string;
  url: string;
  type: string;
}

type Url = {
  delete_code: string;
  original_route: string;
  short_code: string;
}

type Props = {}

function Profile({}: Props) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  const [profile, setProfile] = useState<ProfileData>();
  const [files, setFiles] = useState<File[]>();
  const [urls, setUrls] = useState<Url[]>();  
  const [forceUpdate, setForceUpdate] = useState(false);
  const handleRefresh = () => {
    setForceUpdate(!forceUpdate);
  };

  const fetchProfile = async () => {
    try {
      getProfileData('user/profile', token)
        .then((res: any) => {
          setFiles(res?.data?.data?.files)
          setProfile(res?.data?.data?.profile)
          setUrls(res?.data?.data?.urls)
        })
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchProfile()
  }, [forceUpdate])

  // console.log(files?.length);
  

  // const fetchFiles = async () => {
  //   try {
  //     getProfileData('user/files', token)
  //       .then((res: any) => {
  //         console.log('files', res);
  //       })
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // fetchFiles()

  // const fetchUrls = async () => {
  //   const token = cookies.get('token')
  //   try {
  //     getProfileData('user/urls', token)
  //       .then((res: any) => {
  //         console.log('urls', res);
  //       })
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // fetchUrls()
  
  return (
    <Suspense fallback={<>loadding ... </>}>
      <Helmet>
        <title>الملف الشخصى</title>
      </Helmet>
      <ProfileContent>
        <Drawer/>
        {files && urls && <Div>
          <Container fluid >
            <Row>
              <Col xs={12} >
                <ProfileContentWrapper>
                  <MemoUpdateProfileForm profileData={profile} handleRefresh={handleRefresh} />
                  {/* {files.length !== 0 && <MemoFilesSection filesData={files} token={token} handleRefresh={handleRefresh} />} */}
                  {/* <TableWrapper> */}
                    {urls.length !== 0 && <MemoLinksSection urlsData={urls} token={token} handleRefresh={handleRefresh}/>}
                  {/* </TableWrapper> */}
                </ProfileContentWrapper>
              </Col>
            </Row>
          </Container>
        </Div>}
      </ProfileContent>
    </Suspense>
  )
}

const MemoProfile = memo(Profile)

export default MemoProfile;