import React, { useEffect, useState } from 'react'
import { getFiles, getRecentFiles } from '../../Services/DataServices';
import { GuestFilesWrapper } from '../../components/HomePage/FilesFormSection/FilesFormSection.styled';
import { Col, Container, Row } from 'react-grid-system';
import MemoFilesSection from '../../components/ProfilePage/FilesSection';
import Cookies from 'universal-cookie';

type Props = {}

type File = {
  delete_code: string;
  extension: string;
  image_url: string;
  title: string;
  url: string;
  type: string;
}

function RecentFiles({}: Props) {
  const [files, setFiles] = useState<File[]>();
  const [userFiles, setUserFiles] = useState<File[]>();
  const [forceUpdate, setForceUpdate] = useState(false);
  const fetchRecentFiles = async () => {
    try {
      getRecentFiles('recent_files')
        .then((res) => {
          if(res?.data.success){
            // console.log(res.data);
            setFiles(res?.data.data)
          } else {
            window.location.href = '/login';
          }
        })
    } catch (error) {
      console.log(error);
    }
  }
  const [currentPage, setCurrentPage] = useState(1);

  const cookies = new Cookies();
  const token = cookies.get('token');
  const is_active = cookies.get('is_active');
  // const [forceUpdate, setForceUpdate] = useState(false);
  const [paginationNumber, setPaginationNumber] = useState(1)
  const handleRefresh = () => {
    // setFiles([])
    setForceUpdate(!forceUpdate);
  };
  const fetchFiles = async () => {
    try {
      await getFiles('user/files', currentPage, token)
      .then((res: any) => {
        setUserFiles([])
        setUserFiles(res?.data?.data?.data)
        setPaginationNumber(res?.data.data.last_page)
      })
    } catch (error) {
      console.log(error);
      
    }
  }
  useEffect(() => {
    if(token){
      fetchFiles()
    }
  }, [forceUpdate, currentPage])



  // function createArray(length: number) {
  //   return Array(length).fill(0).map((_, i) => i + 1);
  // }
  
  // const myArray = createArray(paginationNumber); 
  // // console.log(myArray);
  // const handleNext = () => {
  //   if(currentPage < paginationNumber){
  //     setCurrentPage(currentPage + 1)
  //   }
  // }
  // const handleNPrev = () => {
  //   if(currentPage !== 1){
  //     setCurrentPage(currentPage - 1)
  //   }
  // }
  useEffect(() => {
    if(!token || !is_active){
      fetchRecentFiles()
    }
  }, [forceUpdate])
  return (
    <GuestFilesWrapper>
      <Container>
        <Row>
          <Col xs={12}>
            <MemoFilesSection filesData={(token && is_active) ? userFiles : files} token={token} handleRefresh={handleRefresh} />
          </Col>
        </Row>
      </Container>
    </GuestFilesWrapper>
  )
}

export default RecentFiles