import React, { useState } from 'react'
import { ErrorMessage, InputField, InputWrapper, Label } from '../Common/InputTextField/InputTextField.styled';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from 'react-hook-form';
import { ChangePasswordWrapper, Div, FormTitle, SubmitBtn, UpdateProfileFormContent } from './ProfilePage.styled';
import Cookies from 'universal-cookie';
import { updatePassword } from '../../Services/DataServices';

type Props = {}

type Passwords = {
  password: string;
  new_password: string;
  new_password_confirmation: string;
}

const validationSchema = yup.object().shape({
  password: yup.string().required('ادخل كلمة المرور'),
  new_password: yup.string().required('ادخل كلمة المرور'),
  new_password_confirmation: yup.string().required('ادخل كلمة المرور'),

});

function ChangePasswordForm({}: Props) {
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm<Passwords>({
    resolver: yupResolver(validationSchema),
  });
  const cookies = new Cookies();
  const usertoken = cookies.get('token');
  const onSubmit: SubmitHandler<Passwords> = async (data) => {
    const formData = new FormData();
    formData.append('password', data.password);
    formData.append('new_password', data.new_password);
    formData.append('new_password_confirmation', data.new_password_confirmation);
    try {
      setLoading(true);
      // console.log(formData);
      await updatePassword('user/change-password', formData, usertoken)
        .then((res) => {
          return res;
        })
    } catch (error) {
      console.log(error);
      
    }
  };
  return (
    <ChangePasswordWrapper>
      <FormTitle>تغيير كلمة المرور</FormTitle>
      <UpdateProfileFormContent onSubmit={handleSubmit(onSubmit)}>
        <Div>
          <InputWrapper>
            <Label> كلمة المرور القديمة</Label>
            <InputField type='password' placeholder='كلمة المرور القديمة' {...register("password", { required: true })}/>
            {errors.password && <ErrorMessage>ادخل كلمة المرور</ErrorMessage>}
          </InputWrapper>
          <InputWrapper>
            <Label>كلمة المرور الجديدة</Label>
            <InputField type='password' placeholder='كلمة المرور الجديدة' {...register("new_password", { required: true })}/>
            {errors.new_password && <ErrorMessage>ادخل كلمة المرور</ErrorMessage>}
          </InputWrapper>
          <InputWrapper>
            <Label> تأكيد كلمة المرور</Label>
            <InputField type='password' placeholder='تأكيد كلمة المرور' {...register("new_password_confirmation", { required: true })}/>
            {errors.new_password_confirmation && <ErrorMessage>ادخل كلمة المرور</ErrorMessage>}
          </InputWrapper>
          <SubmitBtn type="submit" value={'تعديل'} />
        </Div>
      </UpdateProfileFormContent>
    </ChangePasswordWrapper>
  )
}

export default ChangePasswordForm