import React from 'react'
import { DrawerList, HeaderLink, HeaderList, Li } from './Header.styled'

type Props = {}

function Drawer({}: Props) {
  return (
    <DrawerList>
      <Li>
        <HeaderLink to='/files' >الملفات</HeaderLink>
      </Li>
      <Li>
        <HeaderLink to='/urls' >الروابط</HeaderLink>
      </Li>
    </DrawerList>
  )
}

export default Drawer