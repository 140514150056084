import React, { Suspense, useEffect, useState } from 'react'
import { getFiles, getPublicFiles } from '../../Services/DataServices';
import { Div, ProfileContent } from '../../Layouts/Layout/Layout.styled';
import Drawer from '../../components/Common/Header/Drawer';
import { Col, Container, Row } from 'react-grid-system';
import { Pagination, PaginationNumber, ProfileContentWrapper } from '../../components/ProfilePage/ProfilePage.styled';
import MemoFilesSection from '../../components/ProfilePage/FilesSection';
import { useLocation } from 'react-router-dom';

type File = {
  delete_code: string | null;
  extension: string;
  title: string;
  url: string;
}

type Props = {}

function PublicFilesPage({}: Props) {
  const [files, setFiles] = useState<File[]>()
  const [forceUpdate, setForceUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationNumber, setPaginationNumber] = useState(1)
  const location = useLocation();
  const url = location.pathname;
  const userUrl = url.split('/').pop();
  const fetchFiles = async () => {
    try {
      await getPublicFiles(`user_files_page/${userUrl}`, currentPage)
      .then((res) => {
        setFiles(res?.data?.data?.data)
        setPaginationNumber(res?.data.data.last_page)
      })
    } catch (error) {
      console.log(error);
      
    }
  }
  useEffect(() => {
    fetchFiles()
  }, [forceUpdate, currentPage])

  const handleRefresh = () => {
    setForceUpdate(!forceUpdate);
  };

  const myArray = createArray(paginationNumber); 
  function createArray(length: number) {
    return Array(length).fill(0).map((_, i) => i + 1);
  }

  const handleNext = () => {
    if(currentPage < paginationNumber){
      setFiles([])
      setCurrentPage(currentPage + 1)
    }
  }
  const handlePageNumber = (item: number) => {
    setFiles([])
    setCurrentPage(item)
  }
  const handleNPrev = () => {
    if(currentPage !== 1){
      setFiles([])
      setCurrentPage(currentPage - 1)
    }
  }
  

  return (
    <Suspense fallback={<>loadding ... </>} >
    <ProfileContent>
      <Container >
        <Row>
          <Col xs={12} >
            <ProfileContentWrapper> 
              <MemoFilesSection filesData={files && files as any} handleRefresh={handleRefresh} />
                {files && paginationNumber > 1 && <Pagination>
                  <PaginationNumber onClick={handleNPrev} >السابق</PaginationNumber>
                  {myArray.map((item) => <PaginationNumber key={item} onClick={() => handlePageNumber(item)}>{item}</PaginationNumber>)}
                  <PaginationNumber onClick={handleNext} >التالى</PaginationNumber>
                </Pagination>}
            </ProfileContentWrapper>
          </Col>
        </Row>
      </Container>
    </ProfileContent>
  </Suspense>
  )
}

export default PublicFilesPage