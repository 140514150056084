import React, { useEffect } from 'react'
import Logo from '../../images/logo.webp';
import { LogoImage, P, Wrapper } from './VerifyPage.styled';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
type Props = {}
const BaseUrl = process.env.REACT_APP_API_ENDPOINT;
function VerifyPage({}: Props) {

  // const { param } = useParams();
  // const { url } = useParams<string>();

  const location = useLocation();
  // console.log(location.pathname);
  const url = location.pathname;
  const activationkey = url.split('/').pop();
  // console.log(activationkey);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/user/activate/${activationkey}`);
        // Handle the response data
        console.log(response.data);
        // Redirect to another page if needed
        navigate('/');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [])
  return (
    <Wrapper>
      <LogoImage src={Logo} alt='logo' />
      <P>loading ...</P>
    </Wrapper>
  )
}

export default VerifyPage