import React, { memo, Suspense, useEffect, useState } from 'react'
import { Div, ProfileContent } from '../../Layouts/Layout/Layout.styled';
import Drawer from '../../components/Common/Header/Drawer';
import { Col, Container, Row } from 'react-grid-system';
import { Pagination, PaginationNumber, ProfileContentWrapper } from '../../components/ProfilePage/ProfilePage.styled';
import Cookies from 'universal-cookie';
import { getFiles } from '../../Services/DataServices';
import MemoLinksSection from '../../components/ProfilePage/LinksSection';

type Url = {
  delete_code: string;
  original_route: string;
  short_code: string;
}

type Props = {}

function Urls({}: Props) {
  const [urls, setUrls] = useState<Url[]>()
  const [currentPage, setCurrentPage] = useState(1);
  const cookies = new Cookies();
  const token = cookies.get('token');
  const [forceUpdate, setForceUpdate] = useState(false);
  const [paginationNumber, setPaginationNumber] = useState(1)
  const fetchFiles = async () => {
    try {
      await getFiles('user/urls', currentPage, token)
      .then((res) => {
        setUrls(res?.data?.data?.data)
        setPaginationNumber(res?.data.data.last_page)
      })
    } catch (error) {
      console.log(error);
      
    }
  }
  useEffect(() => {
    fetchFiles()
  }, [forceUpdate, currentPage])

  const handleRefresh = () => {
    setForceUpdate(!forceUpdate);
  };
  function createArray(length: number) {
    return Array(length).fill(0).map((_, i) => i + 1);
  }
  
  const myArray = createArray(paginationNumber); 
  const handleNext = () => {
    if(currentPage < paginationNumber){
      setCurrentPage(currentPage + 1)
    }
  }
  const handleNPrev = () => {
    if(currentPage !== 1){
      setCurrentPage(currentPage - 1)
    }
  }
  
  return (
    <Suspense fallback={<>loadding ... </>} >
      <ProfileContent>
        <Drawer/>
        <Div>
          <Container fluid >
            <Row>
              <Col xs={12} >
                <ProfileContentWrapper>
                  <MemoLinksSection urlsData={urls} token={token} handleRefresh={handleRefresh}/>
                  {urls && paginationNumber > 1 && <Pagination>
                    <PaginationNumber onClick={handleNPrev} >السابق</PaginationNumber>
                    {myArray.map((item) => <PaginationNumber key={item} onClick={() => setCurrentPage(item)}  >{item}</PaginationNumber>)}
                    <PaginationNumber onClick={handleNext} >التالى</PaginationNumber>
                  </Pagination>}
                </ProfileContentWrapper>
              </Col>
            </Row>
          </Container>
        </Div>
      </ProfileContent>
    </Suspense>
  )
}

const MemoUrls = memo(Urls)

export default MemoUrls;