import { memo, Suspense } from 'react'
// import MemoLoader from '../../components/Loader'
import { Helmet } from 'react-helmet'
import { Outlet } from 'react-router-dom';
import Header from '../../components/Common/Header/Header';
import MemoFooter from '../../components/Common/Footer/Footer';
import { ProfileLayoutWrapper } from './Layout.styled';


type Props = {
  title?: string;
  description?: string;
}

const ProfileLayout = ({title, description}: Props) => {
  return (
    <Suspense fallback={<>loading</>} >
      <ProfileLayoutWrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <Header/>
        <Outlet />
        <MemoFooter />
      </ProfileLayoutWrapper>
    </Suspense>
  )
}

const MemoProfileLayout = memo(ProfileLayout);
export default MemoProfileLayout;