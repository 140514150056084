import React, { useEffect } from 'react'
import Login from '../../components/Login/Login'
import { useNavigate } from 'react-router-dom';

type Props = {}

export default function LoginPage({}: Props) {
  // const navigate = useNavigate()
 
  return (
    <Login/>
  )
}