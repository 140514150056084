

import { memo, Suspense, useEffect, useState } from 'react'
import { Div, ProfileContent } from '../../Layouts/Layout/Layout.styled';
import Drawer from '../../components/Common/Header/Drawer';
import { Col, Container, Row } from 'react-grid-system';
import { Pagination, PaginationNumber, ProfileContentWrapper } from '../../components/ProfilePage/ProfilePage.styled';
import { getFiles } from '../../Services/DataServices';
import Cookies from 'universal-cookie';
import ReactPaginate from 'react-paginate';
import MemoFilesSection from '../../components/ProfilePage/FilesSection';

type File = {
  delete_code: string;
  extension: string;
  image_url: string;
  title: string;
  url: string;
  type: string;
}

type Props = {}

function Files({}: Props) {
  // const [currentPage, setCurrentPage] = useState(1);
  const [files, setFiles] = useState<File[]>()
  const [currentPage, setCurrentPage] = useState(1);

  const cookies = new Cookies();
  const token = cookies.get('token');
  const [forceUpdate, setForceUpdate] = useState(false);
  const [paginationNumber, setPaginationNumber] = useState(1)
  const handleRefresh = () => {
    setFiles([])
    setForceUpdate(!forceUpdate);
  };
  const fetchFiles = async () => {
    try {
      await getFiles('user/files', currentPage, token)
      .then((res) => {
        setFiles([])
        setFiles(res?.data?.data?.data)
        setPaginationNumber(res?.data.data.last_page)
      })
    } catch (error) {
      console.log(error);
      
    }
  }
  useEffect(() => {
    fetchFiles()
  }, [forceUpdate, currentPage])



  function createArray(length: number) {
    return Array(length).fill(0).map((_, i) => i + 1);
  }
  
  const myArray = createArray(paginationNumber); 
  // console.log(myArray);
  const handleNext = () => {
    if(currentPage < paginationNumber){
      setCurrentPage(currentPage + 1)
    }
  }
  const handleNPrev = () => {
    if(currentPage !== 1){
      setCurrentPage(currentPage - 1)
    }
  }
  return (
    <Suspense fallback={<>loadding ... </>} >
      <ProfileContent>
        <Drawer/>
        <Div>
          <Container fluid >
            <Row>
              <Col xs={12} >
                <ProfileContentWrapper> 
                  <MemoFilesSection filesData={files} token={token} handleRefresh={handleRefresh} />
                  {files && paginationNumber > 1 && <Pagination>
                    <PaginationNumber onClick={handleNPrev} >السابق</PaginationNumber>
                    {myArray.map((item) => <PaginationNumber key={item} onClick={() => setCurrentPage(item)}  >{item}</PaginationNumber>)}
                    <PaginationNumber onClick={handleNext} >التالى</PaginationNumber>
                  </Pagination>}
                  {/* <MemoLinksSection urlsData={urls} token={token} handleRefresh={handleRefresh}/> */}
                </ProfileContentWrapper>
              </Col>
            </Row>
          </Container>
        </Div>
      </ProfileContent>
    </Suspense>
  )
}
const MemoFilesPage = memo(Files)
export default MemoFilesPage;