import styled, { css } from "styled-components";
import { Submit } from "../Login/Login.styled";

export const ProfileContentWrapper = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 290px);
  min-height: calc(100vh - 290px);
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height: calc(100vh - 340px); */
  /* padding: 60px 0; */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  &:first-of-type {
    margin-bottom: 40px;
  }
  /* padding-top: 40px */
`;

export const Title = styled.h3`
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  color: #00000090;
  margin-bottom: 14px;
`;

export const FilesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  padding: 12px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-shadow: 0 0 5px 0px #00000060;
  border-radius: 12px;
  padding: 8px;
  p, a{
    text-decoration: none;
    cursor: pointer;
    color: #00000060;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  svg {
    padding: 2px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    background-color: transparent;
    transition: 0.3s ease all;
    fill: #00000060;
    /* &:hover{
      fill: #00000070;
      background-color: #00000030;
    } */
  }
`;

export const FileItem = styled.div`
  background-color: #00000030;
  max-width: 150px;
  min-width: 150px;
  max-height: 150px;
  min-height: 150px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .file{
    transform: scale(4)
  }
  /* svg{
    &:first-of-type{
    }
  } */
  /* box-shadow: 0 0 5px 0px #00000060; */
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s ease all;
    transform: scale(1);
    z-index: 0;
  }
  p{
    margin: 12px;
    z-index: 1;
    cursor: pointer;
  }
  &:hover {
    img {
      transform: scale(1.1) ;
    }
  }
  &::after{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #00000020;
    top: 0;
    left: 0;
  }
`;

export const Success = styled.span<{
  readonly copied?: boolean;
}>`
  position: fixed;
  width: 70%;
  font-size: 20px;
  background-color: #6acc89;
  top: 30px;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
  text-align: center;
  border-radius: 12px;
  line-height: 50px;
  transition: 0.3s ease all;
  opacity: 0;
  ${({copied}) => copied && css`
    opacity: 1;
  `}
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }
  td{
    a{
      color: #00000080;
      text-decoration: none;
    }
    &:last-of-type{
      cursor: pointer;
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  gap: 12px;
  margin-bottom: 20px;
  `;

export const PaginationNumber = styled.p`
  background-color: #171345;
  width: 40px;
  height: 40px; 
  padding: 0 4px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  font-size: 0.8rem;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;


export const MegaFileItem = styled(FileItem)`
  max-width: 18%;
  min-width: 18%;
  height: auto;
  max-height: unset;
  min-height: 50%;
`;

export const FileActions = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  padding: 4px 8px;
  border-radius: 8px;
  cursor: pointer;
  z-index: 2;
  svg {
    padding: 2px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    background-color: transparent;
    transition: 0.3s ease all;
    fill: #00000040;
    &:hover{
      fill: #00000070;
      background-color: #00000030;
    }
  }
`;

export const FormTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 20px;
  color: #00000070;
`;

export const UpdateProfileFormContent = styled.form`
  width: 100%;
 
  & > .test{
    width: 100%;
  }
`;

export const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  &:last-child{
    margin: 0 auto;
  }
`;

export const HorizontalDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const SubmitBtn = styled(Submit)`
  margin: 0 auto ;
  padding: 0 50px;
`;

export const ChangePasswordWrapper = styled.div`
  position: relative;
  &::before{
    position: absolute;
    content: '';
    width: 2px ;
    height: 100%;
    background-color: #00000060;
    border-radius: 6px;
    left: -16px;
  }
  @media(max-width: 992px){
    margin: 30px 0;
    &::before{
      display: none;
    }
  }
`;

export const ProfileFormsWrapper = styled.div`
  padding: 60px 0 30px;
`;

export const TableWrapper = styled.div`
  max-width: calc(100vw - 290px);
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 16px;
  &::-webkit-scrollbar {
    width: 100%;
    height: 12px;
    position: fixed;
    right: 0;
    /* bottom: -10px; */
    /* display: none; */
  }
  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid transparent;
    background-clip: content-box;
    background-color: #627B97;
  }
  @media (max-width: 992px) {
    max-width: unset;
  }
`;