import React, { useState } from 'react'
import { LoginForm, LogoImage, Submit } from '../../components/Login/Login.styled';
import { Container, Row, Col } from 'react-grid-system';
import Logo from '../../images/logo.webp'
import { ErrorMessage, InputField, InputWrapper, Label } from '../../components/Common/InputTextField/InputTextField.styled';
import { forgetPassword } from '../../Services/DataServices';

import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from 'react-hook-form';


type Props = {}

type Inputs = {
  email: string,
};
const validationSchema = yup.object().shape({
  email: yup.string().email('invalid email').required('ادخل البريد'),
  // email: yup.string().email('Invalid email format').required('Email is required'),   

});

function ForgetPassword({}: Props) {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const formData = new FormData();
    formData.append('email', data.email);
    try {
      setLoading(true);
      await forgetPassword('user/forgot/password', formData)
        .then((res) => {          
          setMessage(res?.data.message)
          return res;
        })
      } catch (error) {
      console.log(error);
      
    } finally {
      setLoading(false);
    }
  }
  return (
    <Container>
      <Row justify='center' >
        <Col lg={5}  >
          <LoginForm onSubmit={handleSubmit(onSubmit)}>
            <LogoImage src={Logo} alt="logo" />
            <InputWrapper>
              <Label>اسم المستخدم </Label>
              <InputField type='email' placeholder='اسم المستخدم او البريد' {...register("email", { required: true })}/>
              {errors.email && <ErrorMessage>ادخل اسم المستخدم او البريد</ErrorMessage>}
            </InputWrapper>
            {message && <span>{message}</span> }
            <Submit type="submit" value={loading ? "loading":"تأكيد"} disabled={loading} />
          </LoginForm>
        </Col>
      </Row>
    </Container>
  )
}

export default ForgetPassword;