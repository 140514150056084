import { ErrorMessageForm, Forget, LoginForm, LogoImage, Submit } from './Login.styled'
import { Container, Row, Col } from 'react-grid-system'
import { ErrorMessage, InputField, InputWrapper, Label } from '../Common/InputTextField/InputTextField.styled'
import Logo from '../../images/logo.webp'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup';
import Cookies from 'universal-cookie'
import { useEffect, useState } from 'react'
import { loginService } from '../../AuthHelpers/LoginHelper'

import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from 'react-router-dom'

type Inputs = {
  username: string,
  password: string,
};

type Props = {}

const validationSchema = yup.object().shape({
  username: yup.string().required('ادخل اسم المستخدم او البريد'),
  password: yup.string().required('ادخل كلمة المرور'),
  // email: yup.string().email('Invalid email format').required('Email is required'),   

});

const Login = ({}: Props) => {
  const cookies = new Cookies();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      await loginService(data).then((res) => {
        if (res.status === 200) {
          cookies.set("token", res?.data?.data?.token, {
            path: "/",
          });
          
          cookies.set("is_active", res?.data?.data?.is_active, {
            path: "/",
          });

          // console.log(res.data.data);
          
          navigate("/");
        }
      })
    } catch (error) {
      console.log(error);
      setErrorMessage(true)
    } finally {
      setLoading(false);
    }
  }
  // console.log('errorMessage', errorMessage);
  
  useEffect(() => {
    const token = cookies.get('token');    
    if (token) {
      navigate('/');
    }
  }, []);

  return (
    <Container>
      <Row justify='center' >
        <Col lg={5}  >

          <LoginForm onSubmit={handleSubmit(onSubmit)} >
            <LogoImage src={Logo} alt="logo" />
            <InputWrapper>
              <Label>اسم المستخدم </Label>
              <InputField type='text' placeholder='اسم المستخدم او البريد' {...register("username", { required: true })}/>
              {errors.username && <ErrorMessage>ادخل اسم المستخدم او البريد</ErrorMessage>}
            </InputWrapper>
            <InputWrapper>
              <Label>كلمة المرور</Label>
              <InputField type='password' placeholder='كلمة المرور' {...register("password", { required: true })}/>
              {errors.password && <ErrorMessage>ادخل كلمة المرور</ErrorMessage>}
            </InputWrapper>
            {errorMessage && <ErrorMessageForm>قد يكون هذا الحساب غير مفعل او غير مسجل لدينا</ErrorMessageForm> }
            <Forget to='/forget_password' >forget password</Forget>
            <Submit type="submit" value={loading ? "loading":"تسجيل الدخول"} disabled={loading} />
          </LoginForm>
        </Col>
      </Row>
    </Container>
  )
}

export default Login;