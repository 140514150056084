import React, { Suspense, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-grid-system'
import parse from 'html-react-parser';
import { StaticPageWrapper } from '../../components/StaticPage/StaticPage.styled';

type Props = {
  slug: string;
  title: string;
  content: string;
  url: string;
  description?: string;
}


function About({slug, title, content, url, description}: Props) {
  const {t} = useTranslation()  

  return (
    <Suspense fallback={<>loading ...</>}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Container >
        <Row>
          <Col xs={12} >
          <StaticPageWrapper>

            {parse(content)}
          </StaticPageWrapper>
          </Col>
        </Row>
      </Container>
    </Suspense>
  )
}

const MemoAbout = React.memo(About)

export default MemoAbout;