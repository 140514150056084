import VerifyPage from "../../components/VerifyPage/VerifyPage"

type Props = {}

function VerificationPage({}: Props) {
  return (
    <VerifyPage/>
  )
}

export default VerificationPage