import React, { useState } from 'react'
import { NavIcon } from './Header.styled';

type Props = {
  isOpen?: boolean;
  setIsOpen?: () => void;
}

export default function BurgerButton({isOpen, setIsOpen}: Props) {
  // const [isOpen, setIsOpen] = useState(false);

  return (
    <NavIcon className={isOpen ? 'open' : ''} onClick={setIsOpen}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </NavIcon>
  )
}