import React, { memo, useState } from 'react'
import { SectionWrapper, Table, TableWrapper, Title } from './ProfilePage.styled'
import { deleteData } from '../../Services/DataServices';



type Urls = {
  delete_code: string;
  original_route: string;
  short_code: string;
} 

type Props = {
  urlsData?: Urls[];
  token: string;
  handleRefresh: any;
}

const LinksSection = ({urlsData, token, handleRefresh}: Props) => {
  const [Links, setLinks] = useState<Urls[]>()
  const handleDelete = async (fileId: string) => {
    try {
      await deleteData('delete-url', {delete_code: fileId}, token )
        .then((res) => {
          setLinks(urlsData?.filter(link => link.delete_code !== fileId));
          handleRefresh()
        })
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  }
  // console.log(urlsData);
  
  return (
    <SectionWrapper>
      <Title>أحدث الروابط</Title>
      <TableWrapper>

        <Table>
          <thead>
            <tr>
              <th></th>
              <th>الرابط الاساسى</th>
              <th>الرابط المختصر</th>
              <th>حذف</th>
            </tr>
          </thead>
          <tbody>
            {urlsData?.map((item, index) => (
              <tr key={index} >
                <td>{index+1}</td>
                <td><a href={item.original_route} target="_blank">{item.original_route}</a></td>
                <td><a href={item.short_code} target="_blank">{item.short_code}</a></td>
                <td onClick={() => handleDelete(item.delete_code)} >حذف</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </SectionWrapper>
  )
}

const MemoLinksSection = memo(LinksSection)

export default MemoLinksSection