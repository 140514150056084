import { memo } from "react"
import { FooterWrapper, LinksWrapper, P } from "./Footer.styled"
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { Link } from "react-router-dom";

type Props = {}

const Footer = (props: Props) => {
  return (
    <FooterWrapper>
      <LinksWrapper>
        <li>
          <Link to="/about" >العجلان و العيد</Link>
        </li>
        <li>
          <Link to="/services" >الخدمات</Link>
        </li>
        <li>
          <Link to="/privacy" >الخصوصية</Link>
        </li>
        <li>
          <Link to="/terms-and-conditions" >الشروط و الاحكام</Link>
        </li>
        <li>
          <Link to="/faq" >أسئلة</Link>
        </li>
      </LinksWrapper>
      <P><IoCheckmarkCircle/>جميع الحقوق محفوظة لموقع العجلان والعيد</P>
      <P><MdOutlineAlternateEmail />برمجة وتصميم ديموفنف</P>
    </FooterWrapper>
  )
}

const MemoFooter = memo(Footer)

export default MemoFooter