import { Link } from "react-router-dom";
import styled from "styled-components";

export const LoginForm = styled.form`
  width: 100%;
  min-height: calc(100vh - 278px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
`;

export const ErrorMessageForm = styled.p`
  padding: 12px;
  width: 100%;
  background-color: #e84f4140;
  text-align: center;
`;

export const LogoImage = styled.img`
  max-width: 120px; 
  background-color: #252062;
  border-radius: 20px;
  margin: 20px auto;
  padding: 12px;
`;

export const Submit = styled.input`
  font-size: 18px;
  font-weight: 400;
  line-height: 54px;
  padding: 0 20px;
  border-radius: 28px;
  color: #627B97;
  background-color: #EAE9E8;
  text-decoration: none;
  cursor: pointer;
  margin: 0 auto 20px auto;
`;

export const Forget = styled(Link)`
  color: #252061;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
`;