
import React, { memo, Suspense, useState } from 'react'
import { FileActions, FileItem, FilesWrapper, ItemWrapper, MegaFileItem, SectionWrapper, Success, Title } from './ProfilePage.styled'
import { createData, deleteData } from '../../Services/DataServices';
import { MdInsertDriveFile } from "react-icons/md";
import { FaCloudDownloadAlt  } from "react-icons/fa";
import { IoMdTrash } from "react-icons/io";
import { FaCopy } from "react-icons/fa6";


type File = {
  delete_code: string | undefined;
  extension: string;
  image_url: string;
  title: string;
  url: string;
  type: string;
}

type Props = {
  filesData?: File[];
  token?: string;
  handleRefresh?: any
}

const FilesSection = ({filesData, token, handleRefresh}: Props) => {
  const [files, setFiles] = useState<File[]>();
  const [copied, setCopied] = useState(false);

  const handleCopy = (link: string) => {
    navigator.clipboard.writeText(link)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        if(copied) {
        }
      })
      .catch(err => console.error('Failed to copy to clipboard', err));
  };

  const handleDelete = async (fileId: string) => {
    try {
      await deleteData('delete_file', {delete_code: fileId}, token )
        .then((res) => {
          setFiles(files?.filter(file => file.delete_code !== fileId));
          handleRefresh()
        })
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  }
  
  return (
    <Suspense fallback={<>loading ... </>} >
      <SectionWrapper>
        <Title>أحدث الملفات</Title>
        <FilesWrapper>
          {filesData?.map((file, index) => (
            <ItemWrapper key={index}>
              <FileItem>
              <Success copied={copied}>تم النسخ</Success>
              
                {(file.type === 'image') ? <img src={file.image_url} alt="" /> : <MdInsertDriveFile className='file' />}
                
                {/* {file.extension === 'png' || file.extension === "jpg" || file.extension === "jpeg" || file.extension === 'svg' && <img src={file.url} alt="" />} */}
                  {file?.delete_code && token && 
                    <FileActions>
                      <IoMdTrash onClick={() => handleDelete(file.delete_code as any)} />
                    </FileActions>
                  }
              </FileItem>
              <a href={file.url} download>
                <FaCloudDownloadAlt/> تنزيل الملف
              </a>
              <p onClick={() => handleCopy(file.image_url)}> <FaCopy /> نسخ الملف</p>
            </ItemWrapper>
          ))}
        </FilesWrapper>
      </SectionWrapper>
    </Suspense>
  )
}

const MemoFilesSection = memo(FilesSection)

export default MemoFilesSection