import styled from "styled-components";

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: 400;
  color: #252062;
  padding: 0 12px;
`;

export const InputField = styled.input`
  height: 30px;
  background-color: #EAE9E8;
  color: #627B97;
  padding: 12px;
  border: 1px solid #627B97;
  border-radius: 16px;
  &:focus-within{
    outline: none;
  }
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: red;
  padding: 0 12px;
  position: absolute;
  bottom: -18px;
  left: 0;
`;