import styled, { createGlobalStyle } from "styled-components";
import styledNormalize from 'styled-normalize';

export const GlobalStyles = createGlobalStyle`
  
  /* @font-face {
    font-family: "Poppins300";
    src: url("./fonts/Poppins-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins400';
    src: url('./fonts/Poppins-Reguler.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins500';
    src: url('./fonts/Poppins-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins600';
    src: url('./fonts/Poppins-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins700';
    src: url('./fonts/Poppins-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
  }
   */
  body {
    /* font-family: 'Poppins', sans-serif; */
    font-family: "Cairo", serif;
    
  }
  body > *{
    /* background-color: red !important; */
    overflow-x: hidden;
  }
  header{
    /* display: none; */
  }
`;

// export default GlobalStyle;
export const AppWrapper = styled.main`
  
  /* font-family: "Poppins" */
`;