// @ts-nocheck
import { memo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Container, Row } from 'react-grid-system';
import { ErrorMessage, InputField, InputWrapper, Label } from '../Common/InputTextField/InputTextField.styled';
import { Submit } from '../Login/Login.styled';
import { CheckboxWrapper, Div, FormTitle, HorizontalDiv, ProfileFormsWrapper, SubmitBtn, UpdateProfileFormContent } from './ProfilePage.styled';
import { updateProfile } from '../../Services/DataServices';
import { token } from 'stylis';
import Cookies from 'universal-cookie';
import ChangePasswordForm from './ChangePasswordForm';

interface ProfileData {
  name: string
  username: string
  email: string
  is_public: boolean
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  username: yup.string().required('UserName is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  is_public: yup.boolean()
  // avatar: yup.mixed().required('Avatar is required'),   

});

type Props = {
  profileData?: ProfileData;
  handleRefresh: any;
}

function UpdateProfileForm({profileData, handleRefresh}: Props) {
  const {register, handleSubmit, formState: { errors }} = useForm<ProfileData>({resolver: yupResolver(validationSchema),});
  const [loading, setLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [num, setNum] = useState(0)
  const cookies = new Cookies();
  const usertoken = cookies.get('token')
  const onSubmit: SubmitHandler<ProfileData> = async (data) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('username', data.username);
    formData.append('email', data.email);
    formData.append('is_public', num);
    try {
      setLoading(true);
      // console.log(formData);
      await updateProfile('user/update-profile', formData, usertoken)
        .then((res) => {
          return res;
        })
    } catch (error) {
      console.log(error);
    }
  };

  const handleIsPublic = () => {
    setIsChecked(!isChecked);
  }
  const handleChange = () => {
    setIsChecked(!isChecked);
    isChecked ? setNum(1) : setNum(0)
  };
  
  return (
    <ProfileFormsWrapper>
      <Container>
        <Row>
          <Col lg={6} md={12}>
            <FormTitle>تغيير بيانات المستخدم</FormTitle>
            <UpdateProfileFormContent onSubmit={handleSubmit(onSubmit)}>
              <Div >
                <InputWrapper>
                  <Label>الاسم</Label>
                  <InputField type='text' placeholder={profileData?.name} {...register("name", { required: true })}/>
                  {errors.name && <ErrorMessage>ادخل الاسم</ErrorMessage>}
                </InputWrapper>
                <InputWrapper>
                  <Label>اسم المستخدم </Label>
                  <InputField type='text' placeholder={profileData?.username} {...register("username", { required: true })}/>
                  {errors.username && <ErrorMessage>ادخل اسم المستخدم  </ErrorMessage>}
                </InputWrapper>
                <InputWrapper>
                  <Label>البريد الالكترونى</Label>
                  <InputField type='email' placeholder={profileData?.email} {...register("email", { required: true })}/>
                  {errors.email && <ErrorMessage>ادخل البريد</ErrorMessage>}
                </InputWrapper>
                <HorizontalDiv>
                  <CheckboxWrapper>
                    <Label htmlFor="is_public"> يمكن مشاركته : </Label>
                    <input type="checkbox" checked={num === 1 ? true: false} {...register('is_public')} onChange={handleChange} />
                    
                  </CheckboxWrapper>
                  <SubmitBtn type="submit" value={'تعديل'} />
                </HorizontalDiv>
              </Div>
            </UpdateProfileFormContent>
          </Col>
          <Col lg={6} md={12}>
            <ChangePasswordForm/>
          </Col>
        </Row>
      </Container>
    </ProfileFormsWrapper>

  )
}

const MemoUpdateProfileForm = memo(UpdateProfileForm)

export default MemoUpdateProfileForm;