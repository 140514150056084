import { Outlet } from 'react-router-dom'
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import { AppWrapper } from './BaseLayout.styled';
import {memo, Suspense, useEffect, useState} from 'react';
import Header from '../../components/Common/Header/Header';
import MemoFooter from '../../components/Common/Footer/Footer';
import { getSettings } from '../../Services/DataServices';
import Cookies from 'universal-cookie';
import Loader from '../../components/Loader/Loader';
// import MemoLoader from '../../components/Loader';
type Props = {}

function BaseLayout({}: Props) {
  const cookies = new Cookies();
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false);

  const fetchSettings = async () => {
    try {
      setLoading(true)
      getSettings('settings')
        .then((res: any) => {
          cookies.set('is_public', res.data[2].value)
        })
      } catch (error) {
        console.log(JSON.stringify(error));
      } finally {
        setLoading(false)
        
    }
  }

  useEffect(() => {
    fetchSettings()
  }, [])

  return (
    <Suspense fallback={<Loader/>} >
      <AppWrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>العجلان و العيد</title>
        </Helmet>
        <Header/>
        <Outlet />
        <MemoFooter />
      </AppWrapper>
    </Suspense>
  )
}

const MemoBaseLayout = memo(BaseLayout)

export default MemoBaseLayout