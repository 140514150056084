import axios from "axios";
const BaseUrl = process.env.REACT_APP_API_ENDPOINT;

// const AxiosInstance = axios.create({
//   baseURL: BaseUrl,
// });
export async function createData(url: string, formData: any, token?: string) {
  const response = await axios.post(`${BaseUrl}/${url}`, formData, {
    headers: {
      "content-type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
}

export async function deleteData(url: string, formData: any, token?: string) {
  const response = await axios.post(`${BaseUrl}/${url}`, formData, {
    headers: {
      "content-type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
}


export async function getProfileData(url: string, token: string) {
  const response = await axios.get(`${BaseUrl}/${url}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
  if (response !== undefined) if (response.status === 200) return response;
}

export async function updateProfile(url: string, formData: any, token: string) {
  const response = await axios.post(`${BaseUrl}/${url}`, formData, {
    headers: {
      "content-type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
}

export async function updatePassword (url: string, formData: any, token: string) {
  const response = await axios.post(`${BaseUrl}/${url}`, formData, {
    headers: {
      "content-type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
}

export const getFiles = async (url: string, page: number, token: string) => {
  const response = await axios.get(`${BaseUrl}/${url}?page=${page}`, {
    headers: {
      // "content-type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
  if (response !== undefined) if (response.status === 200) return response;
}

export const forgetPassword = async (url: string, formData: any) => {
  const response = await axios.post(`${BaseUrl}/${url}`, formData)
  if (response !== undefined) if (response.status === 200) return response;
}

export const resetPassword = async (url: string, formData: any) => {
  const response = await axios.post(`${BaseUrl}/${url}`, formData)
  if (response !== undefined) if (response.status === 200) return response;
}

export const getStaticPages = async(url: string) => {
  const response = await axios.get(`${BaseUrl}/${url}`)
  if (response !== undefined) if (response.status === 200) return response;
}

export const getSettings = async(url: string) => {
  const response = await axios.get(`${BaseUrl}/${url}`)
  if (response !== undefined) if (response.status === 200) return response;
}
export const getRecentFiles = async(url: string) => {
  const response = await axios.get(`${BaseUrl}/${url}`)
  if (response !== undefined) if (response.status === 200) return response;
}

export const getPublicFiles = async (url: string, page: number) => {
  const response = await axios.get(`${BaseUrl}/${url}?page=${page}`, {
    headers: {
      Accept: "application/json",
    },
  })
  if (response !== undefined) if (response.status === 200) return response;
}
