import { Route, Routes, useParams } from "react-router-dom"
import MemoBaseLayout from "../Layouts/BaseLayout/BaseLayout"
import MemoAbout from "../Pages/About/About"
import { lazy, useCallback, useEffect, useState } from 'react'
// import MemoLayout from "../Layouts/Layout/Layout"
import MemoProfile from "../Pages/Profile/Profile"
import ProtectedRoutes from "./ProtectedRoutes"
import MemoAuthLayout from "../Layouts/Layout/Layout"
import RegisterPage from "../Pages/RegisterPage/RegisterPage"
import LoginPage from "../Pages/LoginPage/LoginPage"
import VerificationPage from "../Pages/Verify/verify"
import MemoProfileLayout from "../Layouts/Layout/Layout"
import MemoFilesPage from "../Pages/Files/Files"
import MemoUrls from "../Pages/Urls/Urls"
import ForgetPassword from "../Pages/ForgetPassword/ForgetPassword"
import ResetPassword from "../Pages/ResetPassword/ResetPassword"
import { getSettings, getStaticPages } from "../Services/DataServices"
import PublicFilesPage from "../Pages/PublicFilesPage/PublicFilesPage"
import LockHome from "./LockHome"
import RecentFiles from "../Pages/RecentFiles/RecentFiles"
import VereficationMessagePage from "../Pages/VereficationPage/VereficationPage"
// import VerificationPage from "../Pages/VerificationPage/VerificationPage"


const LazyHome = lazy(() => import('../Pages/Home/Home'))

type StaticPage = {
  slug: string;
  title: string;
  content: string;
  url: string;
  description?: string;
}[]

type Settings = {
  key: string;
  value: string;
}[]

const AppRoutes = () => {
  const [staticPages, setStaticPages] = useState<StaticPage>()
  // const [setitings, setSettings] = useState<Settings>()
 
  const [is_locked, setIsLocked] = useState('0');
  const { url } = useParams<string>();
  const fetchPage = async () => {
    try {
      await getStaticPages('static_pages')
        .then((res) => {
          if(res) {
            setStaticPages(res.data.data)
          }
        })
      
    } catch (error) {
      console.log(error);
      
    }
  }
  useEffect(() => {
    fetchPage()
  }, [url])


  
  return (
    <Routes>
      <Route element={<MemoBaseLayout/>} >
      {/* <Route element={<LockHome />}>
      </Route> */}
        <Route path="/" element={<LazyHome />} />
        <Route path="/recent" element={<RecentFiles />} />
        {staticPages?.map((page) => (
          <Route 
            key={page.slug} 
            path={`/${page.slug}`}
            element={<MemoAbout slug={page.slug} title={page.title} content={page.content} url={page.url} description={page.description} />} />
        ))}
        <Route path="/register" element={< RegisterPage />} />
        <Route path="/login" element={< LoginPage />} />
        <Route path="/forget_password" element={< ForgetPassword />} />
        <Route path="/verify/:id" element={<VerificationPage />} />
        <Route path="/verification" element={<VereficationMessagePage />} />
        <Route path="/reset-password/:token/:email" element={<ResetPassword />} />
        <Route path="/user_files_page/:id" element={<PublicFilesPage />} />

      </Route>
      <Route element={<MemoProfileLayout/>} >
        <Route element={<ProtectedRoutes />}>
          <Route path="/profile" element={<MemoProfile />} />
          <Route path="/files" element={<MemoFilesPage />} />
          <Route path="/urls" element={<MemoUrls />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default AppRoutes;